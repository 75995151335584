import React, { useState, useEffect } from "react"
import { Form, Input } from "antd"
import { showSuccess, showError } from "@action"
import FormModal from "@components/FormModal"

const EditNameModal = ({
  isVisible,
  onCancel,
  record,
  refresh,
  apiClient,
  title,
  name = "",
}) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isVisible) {
      form.setFieldsValue({
        ...record,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  const editData = async (id, comment) => {
    setIsLoading(true)
    let client = new apiClient()
    try {
      if (name !== "attendanceRecord") {
        await client.edit(id, comment)
      } else {
        await client.update(id, comment)
      }
      showSuccess("Change Title Request Success")
      refresh()
      onCancel()
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      showError(errmsg.errors)
    } finally {
      setIsLoading(false)
    }
  }

  const onFinish = values => {
    editData(record.id, {
      id: record.id,
      isEnabled: record.isEnabled,
      ...values,
    })
  }

  return (
    <FormModal
      isCreate={false}
      form={form}
      onCancel={onCancel}
      title={title + " title"}
      isLoading={isLoading}
      visible={isVisible}
    >
      <Form form={form} onFinish={onFinish} preserve={false}>
        <Form.Item
          name="name"
          label="Title"
          rules={[{ required: true, message: "Please input a title" }]}
        >
          <Input placeholder="Input a Title" />
        </Form.Item>
      </Form>
    </FormModal>
  )
}

export default EditNameModal
