import React, { useEffect, useState } from "react"
import {
  Button,
  Space,
  Form,
  Row,
  Col,
  Input,
  Spin,
  InputNumber,
  Select,
} from "antd"
//TODO: change the attendance
import {
  AttendanceRecordClient,
  AttendanceRecordFileClient,
  OtherClient,
} from "@api"
import { showSuccess, errorMessage, showError } from "@action"
import UploadModal from "@components/UploadModal"
import FormModal from "@components/FormModal"
import { PanelTable, EditButton, DeleteButton } from "lib"
import { showSelectProps } from "@object"

const SearchForm = ({ oldSearchParams, setSearchParams }) => {
  const [form] = Form.useForm()
  const { Option } = Select
  const selectProps = { ...showSelectProps, mode: "multiple" }
  const [options, setOptions] = useState({
    positionList: [],
  })
  const onFinish = values => {
    setSearchParams({
      ...oldSearchParams,
      ...values,
      pageIndex: 0,
    })
  }

  useEffect(() => {
    const getOption = async () => {
      try {
        const client = new OtherClient()
        const result = await client.get()
        setOptions(result.content)
      } catch (err) {
        let errmsg = JSON.parse(err.response)
        showError(errmsg.errors)
      }
    }
    getOption()
  }, [])
  return (
    <Row gutter={24} className="admin_table">
      <Form form={form} layout="inline" onFinish={onFinish} preserve={true}>
        <Col span={12}>
          <Form.Item label="Staff Number" name="staffNo">
            <Input placeholder="Input a Staff Number" allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Staff Name" name="staffName">
            <Input placeholder="Input a Staff Name" allowClear />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Position" name="positionIdList">
            <Select {...selectProps} placeholder="Select a Position">
              {options.positionList.map(item => (
                <Option id={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Space>
              <Button shape="round" type="primary" htmlType="submit">
                Search
              </Button>
              <Button
                shape="round"
                onClick={() => {
                  form.resetFields()
                  form.submit()
                }}
              >
                Clear
              </Button>
            </Space>
          </Row>
        </Col>
      </Form>
    </Row>
  )
}

const EditModal = ({
  id,
  isCreate,
  isNew,
  visible,
  onCancel,
  record,
  refresh,
}) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (!isNew && record) {
      form.setFieldsValue({
        ...record,
      })
    } else {
      form.resetFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const onFinish = async values => {
    let client = new AttendanceRecordClient()
    setIsLoading(true)
    try {
      if (isCreate) {
        await client.create(
          id,
          values.staffNo,
          values.latenessTimes,
          values.latenessMinutes,
          values.failingToClock
        )
      } else {
        await client.update(record.id, { id: record.id, ...values })
      }
      showSuccess(isCreate ? "Add Attendance Record" : "Edit Attendance Record")
      refresh()
      onCancel()
    } catch (err) {
      errorMessage(
        isCreate ? "Add Attendance Record" : "Change Attendance Record",
        err
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormModal
      isCreate={isCreate}
      form={form}
      onCancel={onCancel}
      title={"Attendance Record"}
      isLoading={isLoading}
      visible={visible}
    >
      <Form form={form} preserve={false} onFinish={onFinish}>
        {isCreate && (
          <Form.Item
            name="staffNo"
            label="Staff Number"
            rules={[
              {
                required: true,
                message: "Please input a staff Number",
              },
            ]}
          >
            <Input placeholder="Input a Staff Number" />
          </Form.Item>
        )}
        <Form.Item
          name="latenessTimes"
          label="Lateness (Times)"
          rules={[
            {
              required: true,
              message: "Input a Number",
            },
          ]}
        >
          <InputNumber type="number" min={0} />
        </Form.Item>

        <Form.Item
          name="latenessMinutes"
          label="Lateness (Minutes)"
          rules={[
            {
              required: true,
              message: "Input a Number",
            },
          ]}
        >
          <InputNumber type="number" min={0} />
        </Form.Item>

        <Form.Item
          name="failingToClock"
          label="Failing To Clock"
          rules={[
            {
              required: true,
              message: "Input a Number",
            },
          ]}
        >
          <InputNumber type="number" min={0} />
        </Form.Item>
      </Form>
    </FormModal>
  )
}

const AttendanceTable = ({ id }) => {
  const [uploadVisible, setUploadVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [visible, setVisible] = useState(false)
  const [pageMeta, setPageMeta] = useState(null)
  const [modalVars, setModalVars] = useState({
    isCreate: true,
    isNew: true,
    record: null,
  })
  const [searchParams, setSearchParams] = useState({
    id: id,
    orderBy: "id",
    isAscend: true,
    staffNo: null,
    staffName: null,
    positionIdList: null,
    pageSize: 10,
    pageIndex: 0,
  })
  const [isInit, setIsInit] = useState(true)

  const getData = async params => {
    setIsLoading(true)
    let client = new AttendanceRecordClient()
    try {
      console.log(params)
      let res = await client.get2(
        params.id,
        params.orderBy,
        params.isAscend,
        params.staffNo,
        params.staffName,
        params.positionIdList,
        params.pageSize,
        params.pageIndex
      )
      setDataSource(res.list)
      setPageMeta(res.paginationMetadata)
      setIsInit(false)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteData = async id => {
    setIsLoading(true)
    let client = new AttendanceRecordClient()
    try {
      await client.delete(id)
      showSuccess("Delete item success !")
      let newIndex = (pageMeta.totalCount - 1) / pageMeta.pageSize
      if (newIndex <= pageMeta.currentPage - 1) {
        setSearchParams({
          ...searchParams,
          pageIndex: parseInt(newIndex - 1) < 0 ? 0 : parseInt(newIndex - 1),
        })
      } else {
        getData(searchParams)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData(searchParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const columns = [
    { title: "Staff Number", dataIndex: "staffNo", sorter: true },
    {
      title: "Staff Name",
      dataIndex: "staffName",
      sorter: true,
    },
    {
      title: "Position",
      dataIndex: "position",
      sorter: true,
    },
    { title: "Lateness (Times)", dataIndex: "latenessTimes", sorter: true },
    {
      title: "Lateness (Minutes)",
      dataIndex: "latenessMinutes",
      sorter: true,
    },
    {
      title: "Failing To Clock",
      dataIndex: "failingToClock",
      sorter: true,
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <Space size="small">
            <EditButton
              onClick={() => {
                setModalVars({
                  isCreate: false,
                  isNew: false,
                  record: record,
                })
                setVisible(true)
              }}
            />
            <DeleteButton
              onClick={() => {
                deleteData(record.id)
              }}
            />
          </Space>
        )
      },
    },
  ]
  return !isInit ? (
    <>
      <SearchForm
        oldSearchParams={searchParams}
        setSearchParams={values => setSearchParams(values)}
      />
      <UploadModal
        setId={id}
        visible={uploadVisible}
        refresh={() => {
          getData(searchParams)
        }}
        onCancel={() => {
          setUploadVisible(false)
        }}
        title={"Attendance Record"}
        apiClient={AttendanceRecordFileClient}
      />
      <EditModal
        id={id}
        isCreate={modalVars.isCreate}
        isNew={modalVars.isNew}
        visible={visible}
        record={modalVars.record}
        onCancel={() => {
          setVisible(false)
        }}
        refresh={() => getData(searchParams)}
      />
      {/* <Space size="small" className="table-Buttom">
        <Button
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalVars({
              isCreate: true,
              isNew: true,
              record: null,
            })
            setVisible(true)
          }}
        >
          Add
        </Button>

        <Button
          shape="round"
          icon={<UploadOutlined />}
          onClick={() => {
            setUploadVisible(true)
          }}
        >
          Upload
        </Button>

        <Button
          shape="round"
          icon={<DownloadOutlined />}
          onClick={() => {
            DownloadConfigData(AttendanceRecordFileClient, id, false, false)
          }}
        >
          Download
        </Button>
      </Space> */}
      <PanelTable
        dataSource={dataSource}
        columns={columns}
        pageMeta={pageMeta}
        isLoading={isLoading}
        setSearchParams={values =>
          setSearchParams({ ...searchParams, ...values })
        }
        searchParams={searchParams}
      />
    </>
  ) : (
    <Spin size="large" />
  )
}

export default AttendanceTable
