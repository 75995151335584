import React, { useState, useEffect } from "react"
import { Modal, Upload, Form, Checkbox, Button } from "antd"
import { showSuccess, errorMessage } from "@action"
import { UploadOutlined } from "@ant-design/icons"
import { useIntl } from "react-intl"

const UploadModal = ({
  isTranslate = false,
  visible,
  onCancel,
  refresh,
  title,
  apiClient,
  setId = false,
  isSiteKPI = false,
  isSite = false,
}) => {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { formatMessage } = useIntl()

  const excelText = isTranslate ? formatMessage({ id: "Excel" }) : "Excel"
  const uploadText = isTranslate
    ? formatMessage({ id: "upload" }, { name: "" })
    : "Upload"

  const cancelText = isTranslate
    ? formatMessage({ id: "cancel" }, { name: "" })
    : "Cancel"

  const fileLabel = isTranslate ? formatMessage({ id: "file" }) : "File"

  const filePlaceholder = isTranslate
    ? formatMessage({ id: "inputPlaceholder" }, { name: fileLabel })
    : "Select a File"

  const isClearLabel = isTranslate
    ? formatMessage({ id: "replaceAllData" })
    : "Replace All Data"

  const uploadData = async (file, isClear) => {
    setIsLoading(true)

    let client = new apiClient()
    try {
      if (setId) {
        if (!isSiteKPI) {
          await client.upload(file, setId, isClear)
        } else {
          if (isSite) {
            await client.uploadSiteKPI(setId, file, isClear)
          } else {
            await client.uploadIndividualKPI(setId, file, isClear)
          }
        }
      } else {
        await client.upload(file, isClear)
      }
      showSuccess("Upload " + file.filename + " Success")
      refresh()
      onCancel()
    } catch (err) {
      errorMessage("Upload " + file.filename, err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    form.resetFields()
    setFileList([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const handleOnChange = ({ fileList }) => {
    setFileList(fileList)
  }
  const normFile = e => {
    let file = { data: e.file, filename: e.file.name }
    return file
  }
  const onFinish = values => {
    uploadData(values.file, values.isClear)
  }
  return (
    <Modal
      forceRender
      title={`${uploadText} ${title} ${excelText}`}
      destroyOnClose={true}
      confirmLoading={isLoading}
      visible={visible}
      okText={uploadText}
      cancelText={cancelText}
      onCancel={() => {
        if (!isLoading) {
          setFileList([])
          onCancel()
        }
      }}
      onOk={() => form.submit()}
    >
      <Form form={form} onFinish={onFinish} preserve={false}>
        <Form.Item
          valuePropName="file"
          name="file"
          label={fileLabel}
          getValueFromEvent={normFile}
          rules={[{ required: true, message: "Please upload a file" }]}
        >
          <Upload onChange={handleOnChange} beforeUpload={() => false}>
            {fileList.length < 1 && (
              <Button icon={<UploadOutlined />}>{filePlaceholder}</Button>
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          initialValue={false}
          name="isClear"
          label={isClearLabel}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default UploadModal
