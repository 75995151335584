import React from "react"
import { Modal } from "antd"

const FormModal = ({
  isCreate,
  form,
  onCancel,
  title,
  children,
  isLoading,
  visible,
  ...rest
}) => {
  return (
    <Modal
      centered
      forceRender
      title={isCreate ? "Add " + title : "Edit " + title}
      cancelText="Cancel"
      destroyOnClose={true}
      visible={visible}
      okText={isCreate ? "Add" : "Change"}
      onCancel={
        !isLoading
          ? onCancel
          : () => {
              console.log("isLoading")
            }
      }
      onOk={() => form.submit()}
      afterClose={() => form.resetFields()}
      confirmLoading={isLoading}
      {...rest}
    >
      {children}
    </Modal>
  )
}

export default FormModal
